import React from 'react';
import { useTranslation } from 'react-i18next';
import './AboutSection.css';
import aboutImage from '../assets/main_logo.PNG';

function AboutSection() {
  const { t } = useTranslation();

  return (
    <section className="about-section section" id="about">
      <div className="container flex">
        <div className="about-text">
          <h2 className="section-heading">{t('about.sectionTitle')}</h2>
          <p className="about-description">
            {t('about.description')}
          </p>
          <ul className="about-list">
            <li>
              <strong>{t('about.listItems.ragSystem.title')}</strong> {t('about.listItems.ragSystem.description')}
            </li>
            <li>{t('about.listItems.multilingualSupport')}</li>
            <li>{t('about.listItems.customSolutions')}</li>
            <li>{t('about.listItems.comprehensiveSupport')}</li>
          </ul>
        </div>
        <div className="about-image">
          <img src={aboutImage} alt={t('about.imageAlt')} />
        </div>
      </div>
    </section>
  );
}

export default AboutSection;