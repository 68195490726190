import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.css';
import { FaTwitter, FaLinkedin, FaGithub } from 'react-icons/fa';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer-section" id="contact">
      <div className="container">
        <div className="footer-content">
          <p>{t('footer.copyright')}</p>
          <p>
            {t('footer.contactText')}: <a href={`mailto:${t('footer.contactEmail')}`}>{t('footer.contactEmail')}</a>
          </p>
          <div className="social-links">
            <a
              href={t('footer.socialLinks.twitter')}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter className="social-icon" />
            </a>
            <a
              href={t('footer.socialLinks.linkedin')}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin className="social-icon" />
            </a>
            <a
              href={t('footer.socialLinks.github')}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub className="social-icon" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;