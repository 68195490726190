import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslations from './locales/en.json';
import ruTranslations from './locales/ru.json';
import kzTranslations from './locales/kz.json';
i18n
  .use(initReactI18next)
  .init({
    resources: {
      kz: { translation: kzTranslations },
      en: { translation: enTranslations },
      ru: { translation: ruTranslations }

    },
    lng: 'kz', // default language
    fallbackLng: 'kz', // fallback language
    interpolation: {
      escapeValue: false // react already escapes values
    }
  });

export default i18n;