import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './HeroSection.css';

function HeroSection() {
  const { t } = useTranslation();
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = canvas.width = window.innerWidth;
    const height = canvas.height = window.innerHeight;

    const colors = ['#FFFFFF', '#F58220', '#800080', '#FF5733','#006A4E'];
    let fractals = [];

    // Generate fractal trajectories
    function createFractals() {
      for (let i = 0; i < 30; i++) {
        fractals.push({
          x: Math.random() * width,
          y: Math.random() * height,
          vx: Math.random() * 2 - 1,
          vy: Math.random() * 2 - 1,
          size: Math.random() * 3 + 1,
          color: colors[Math.floor(Math.random() * colors.length)],
        });
      }
    }

    // Animate fractals
    function animate() {
      ctx.clearRect(0, 0, width, height);

      fractals.forEach((f) => {
        ctx.beginPath();
        ctx.arc(f.x, f.y, f.size, 0, Math.PI * 2, false);
        ctx.fillStyle = f.color;
        ctx.fill();

        f.x += f.vx;
        f.y += f.vy;

        // Bounce off edges
        if (f.x < 0 || f.x > width) f.vx *= -1;
        if (f.y < 0 || f.y > height) f.vy *= -1;

        // Draw lines between close fractals
        fractals.forEach((other) => {
          const dist = Math.hypot(f.x - other.x, f.y - other.y);
          if (dist < 150) {
            ctx.beginPath();
            ctx.moveTo(f.x, f.y);
            ctx.lineTo(other.x, other.y);
            ctx.strokeStyle = f.color;
            ctx.lineWidth = 0.2;
            ctx.stroke();
          }
        });
      });

      requestAnimationFrame(animate);
    }

    createFractals();
    animate();

    window.addEventListener('resize', () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      fractals = [];
      createFractals();
    });

    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  return (
    <section className="hero-section" id="home">
      {/* Canvas for Fractals */}
      <canvas ref={canvasRef} className="hero-canvas"></canvas>

      {/* Content */}
      <div className="container">
        <h1 className="hero-heading">
          {t('hero.heading')}
        </h1>
        <p className="hero-subtext">
          {t('hero.subtext')}
        </p>
        <a href="#about" className="cta-button">{t('hero.ctaButton')}</a>
      </div>
    </section>
  );
}

export default HeroSection;