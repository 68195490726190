import React from 'react';
import { useTranslation } from 'react-i18next';
import './FeaturesSection.css';
import {
  FaComments,
  FaLaptopCode,
  FaTasks,
  FaCogs,
  FaCloud,
  FaShieldAlt,
} from 'react-icons/fa';

const features = [
  {
    icon: <FaComments />,
    titleKey: 'features.aiAssistant.title',
    descriptionKey: 'features.aiAssistant.description',
  },
  {
    icon: <FaLaptopCode />,
    titleKey: 'features.ragSystem.title',
    descriptionKey: 'features.ragSystem.description',
  },
  {
    icon: <FaTasks />,
    titleKey: 'features.prontoПлан.title',
    descriptionKey: 'features.prontoПлан.description',
  },
  {
    icon: <FaCogs />,
    titleKey: 'features.integration.title',
    descriptionKey: 'features.integration.description',
  },
  {
    icon: <FaCloud />,
    titleKey: 'features.cloudSolutions.title',
    descriptionKey: 'features.cloudSolutions.description',
  },
  {
    icon: <FaShieldAlt />,
    titleKey: 'features.securityPrivacy.title',
    descriptionKey: 'features.securityPrivacy.description',
  },
];

function FeaturesSection() {
  const { t } = useTranslation();

  return (
    <section className="features-section section" id="features">
      <div className="container">
        <h2 className="section-heading">{t('features.sectionTitle')}</h2>
        <div className="features-grid">
          {features.map((feature, index) => (
            <div key={index} className="feature-item" tabIndex="0">
              <div className="feature-icon">{feature.icon}</div>
              <h3 className="feature-title">{t(feature.titleKey)}</h3>
              <p className="feature-description">{t(feature.descriptionKey)}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default FeaturesSection;