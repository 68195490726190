import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Header.css';
import { FaBars, FaTimes } from 'react-icons/fa';

function Header() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header className="header">
      <div className="container flex">
        <div className="logo">Pronto-Sage</div>
        <nav className={`nav ${isMobileMenuOpen ? 'nav-active' : ''}`}>
          <ul className="nav-links">
            <li>
              <a href="#home" onClick={() => setMobileMenuOpen(false)}>
                {t('header.home')}
              </a>
            </li>
            <li>
              <a href="#about" onClick={() => setMobileMenuOpen(false)}>
                {t('header.about')}
              </a>
            </li>
            <li>
              <a href="#features" onClick={() => setMobileMenuOpen(false)}>
                {t('header.features')}
              </a>
            </li>
            <li>
              <a href="#contact" onClick={() => setMobileMenuOpen(false)}>
                {t('header.contact')}
              </a>
            </li>
          </ul>
          <button className="cta-button">{t('header.getStarted')}</button>
          <div className="language-switcher">
          <button 
              className={`lang-btn ${i18n.language === 'kz' ? 'active' : ''}`} 
              onClick={() => changeLanguage('kz')}
            >
              KZ
            </button>
            <button 
              className={`lang-btn ${i18n.language === 'en' ? 'active' : ''}`} 
              onClick={() => changeLanguage('en')}
            >
              EN
            </button>
            <button 
              className={`lang-btn ${i18n.language === 'ru' ? 'active' : ''}`} 
              onClick={() => changeLanguage('ru')}
            >
              RU
            </button>
          </div>
        </nav>
        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
      </div>
    </header>
  );
}

export default Header;